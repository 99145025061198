<template>
  <div class="education">
    <div v-for="(edu, index) in educationCopy" :key="index" class="mb-4">
      <h5>Education {{ index + 1 }}</h5>

      <!-- Institution, Location on the same row -->
      <div class="row">
        <div class="col-md-6 mb-3">
          <label for="institutionName" class="form-label">Institution</label>
          <input type="text" class="form-control" :id="'institutionName' + index" v-model="edu.institutionName"
            @input="updateEducation" />
        </div>

        <div class="col-md-6 mb-3">
          <label for="location" class="form-label">Location</label>
          <input type="text" class="form-control" :id="'location' + index" v-model="edu.location"
            @input="updateEducation" />
        </div>
      </div>

      <div class="row">
        <div class="col-md-6 mb-3">
          <label for="fieldOfStudy" class="form-label">Field of Study</label>
          <input type="text" class="form-control" :id="'fieldOfStudy' + index" v-model="edu.fieldOfStudy"
            @input="updateEducation" />
        </div>
      </div>

      <!-- Start Date -->
      <div class="row">
        <div class="col-md-6 mb-3">
          <label for="startDate" class="form-label">Start Date</label>
          <!-- Month Dropdown -->
          <select class="form-control" v-model="edu.startMonth" @change="updateEducation">
            <option v-for="(month, index) in months" :key="index" :value="index + 1">
              {{ month }}
            </option>
          </select>
        </div>

        <div class="col-md-6 mb-3">
          <label for="startYear" class="form-label">Start Year</label>
          <!-- Year Dropdown -->
          <select class="form-control" v-model="edu.startYear" @change="updateEducation">
            <option v-for="(year, index) in years" :key="index" :value="year">
              {{ year }}
            </option>
          </select>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6 mb-3">
          <label for="endDate" class="form-label">End Date</label>
          <!-- Month Dropdown -->
          <select class="form-control" v-model="edu.endMonth" @change="updateEducation">
            <option v-for="(month, index) in months" :key="index" :value="index + 1">
              {{ month }}
            </option>
          </select>
        </div>

        <div class="col-md-6 mb-3">
          <label for="endYear" class="form-label">End Year</label>
          <!-- Year Dropdown -->
          <select class="form-control" v-model="edu.endYear" @change="updateEducation">
            <option v-for="(year, index) in years" :key="index" :value="year">
              {{ year }}
            </option>
          </select>
        </div>
      </div>

      <!-- Summary -->
      <div class="mb-3">
        <label for="summary" class="form-label">Summary</label>
        <textarea class="form-control" :id="'summary' + index" v-model="edu.summary"
          @input="updateEducation"></textarea>
      </div>

      <!-- Skills (list editor) -->       
      <div class="mb-3">
        <SkillsComponent v-model="edu.skills" title="Relevant Skills" placeholder="Add a new acquired skill" />
      </div>      
      <button class="btn btn-danger btn-sm" @click="removeEducation(index)">Remove</button>
    </div>

    <button class="btn btn-primary" @click="addEducation">Add Education</button>
  </div>
</template>

<script>
import SkillsComponent from "./SkillsComponent.vue";

export default {
  name: "EducationComponent",
  components:{
    SkillsComponent
  },
  props: {
    education: Array,
  },
  data() {
    return {
      months: [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
      ],
      years: Array.from({ length: 100 }, (v, k) => new Date().getFullYear() - k),
      educationCopy: this.education.map(edu => ({
      ...edu,
      startMonth: edu.startDate ? parseInt(edu.startDate.split('-')[1]) : null,
      startYear: edu.startDate ? parseInt(edu.startDate.split('-')[0]) : null,
      endMonth: edu.endDate ? parseInt(edu.endDate.split('-')[1]) : null,
      endYear: edu.endDate ? parseInt(edu.endDate.split('-')[0]) : null,
    })),
    };
  },
  methods: {
    updateEducation() {
      this.educationCopy.forEach(edu => {
        if (edu.startMonth && edu.startYear) {
          edu.startDate = `${edu.startYear}-${edu.startMonth < 10 ? '0' + edu.startMonth : edu.startMonth}`;
        }
        if (edu.endMonth && edu.endYear) {
          edu.endDate = `${edu.endYear}-${edu.endMonth < 10 ? '0' + edu.endMonth : edu.endMonth}`;
        }
      });

      this.$emit("update-education", [...this.educationCopy]);
    },
    addEducation() {
      this.educationCopy.push({
        institutionName: "",
        fieldOfStudy: "",
        location: "",
        startMonth: null,
        startYear: null,
        endMonth: null,
        endYear: null,
        summary: "",
        skills: [""],
      });
      this.updateEducation();
    },
    removeEducation(index) {
      this.educationCopy.splice(index, 1);
      this.updateEducation();
    },
    addSkill(index) {
      this.educationCopy[index].skills.push("");
      this.updateEducation();
    },
    removeSkill(index, sIndex) {
      this.educationCopy[index].skills.splice(sIndex, 1);
      this.updateEducation();
    },
  },
};
</script>
