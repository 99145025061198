<template>
  <div class="edit-resume light-background-box vh90scrollable">
    <h1>Edit Resume Content</h1>
    <small class="text-muted">Click on a section's title to expand and edit its contents</small>

    <hr />

    <!-- User Metadata Section -->
    <div @click="setActiveSection('userMetadata')" class="section-header">
      <h1>{{ currentActiveSection === 'userMetadata' ? 'User Metadata' : ' › User Metadata' }}</h1>
    </div>
    <div v-show="currentActiveSection === 'userMetadata'">
      <UserMetadataComponent :userMetadata="editableResume.userMetadata" @update-user-metadata="updateUserMetadata" />
    </div>
    <hr />

    <!-- Skills Section -->
    <div @click="setActiveSection('skills')" class="section-header">
      <h1>{{ currentActiveSection === 'skills' ? 'Skills' : '› Skills' }}</h1>
    </div>
    <div v-show="currentActiveSection === 'skills'">
      <SkillsComponent v-model="editableResume.skills" title="Skills Showcase" placeholder="Add a skill" />
    </div>
    <hr />

    <!-- Work Experience Section -->
    <div @click="setActiveSection('workExperience')" class="section-header">
      <h1>{{ currentActiveSection === 'workExperience' ? 'Work Experience' : '› Work Experience' }}</h1>
    </div>
    <div v-show="currentActiveSection === 'workExperience'">
      <WorkExperienceComponent :workExperience="editableResume.workExperience"
        @update-work-experience="updateWorkExperience" />
    </div>
    <hr />

    <!-- Education Section -->
    <div @click="setActiveSection('education')" class="section-header">
      <h1>{{ currentActiveSection === 'education' ? 'Education' : '› Education' }}</h1>
    </div>
    <div v-show="currentActiveSection === 'education'">
      <EducationComponent :education="editableResume.education" @update-education="updateEducation" />
    </div>
    <hr />

    <!-- Certifications Section -->
    <div @click="setActiveSection('certifications')" class="section-header">
      <h1>{{ currentActiveSection === 'certifications' ? 'Certifications' : '› Certifications' }}</h1>
    </div>
    <div v-show="currentActiveSection === 'certifications'">
      <CertificationComponent :certifications="editableResume.certifications"
        @update-certifications="updateCertifications" />
    </div>
  </div>
</template>

<script>
import UserMetadataComponent from "./UserMetadataComponent.vue";
import WorkExperienceComponent from "./WorkExperienceComponent.vue";
import EducationComponent from "./EducationComponent.vue";
import CertificationComponent from "./CertificationComponent.vue";
import SkillsComponent from "./SkillsComponent.vue";

export default {
  name: "EditResumeComponent",
  components: {
    UserMetadataComponent,
    WorkExperienceComponent,
    EducationComponent,
    CertificationComponent,
    SkillsComponent,
  },
  props: {
    resumeData: Object,
  },
  data() {
    return {
      editableResume: { ...this.resumeData },
      currentActiveSection: "userMetadata", // Default active section
    };
  },
  methods: {
    setActiveSection(section) {
      // Safely switch active sections
      if (this.currentActiveSection !== section) {
        this.currentActiveSection = section;
      }
      else {
        this.currentActiveSection = "";
      }
    },
    updateUserMetadata(updatedMetadata) {
      this.editableResume.userMetadata = updatedMetadata;
      this.emitUpdatedResume();
    },
    updateWorkExperience(updatedWorkExperience) {
      this.editableResume.workExperience = updatedWorkExperience;
      this.emitUpdatedResume();
    },
    updateEducation(updatedEducation) {
      this.editableResume.education = updatedEducation;
      this.emitUpdatedResume();
    },
    updateCertifications(updatedCertifications) {
      this.editableResume.certifications = updatedCertifications;
      this.emitUpdatedResume();
    },
    emitUpdatedResume() {
      this.$emit("update-resume", { ...this.editableResume });
    },
  },
};
</script>

<style scoped>
.section-header h1 {
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  margin: 0;
  font-size: 1.5rem;
  color: #069;
}
</style>
