<template>
  <div class="pad20 container-fluid">
    <!-- Header Section with Buttons -->
    <div class="d-flex justify-content-between mb-3">
      <button @click="goBack" class="btn btn-secondary mb-3">Back</button>

      <button @click="redirectToHelper" class="cta cta-link btn-secondary mb-0">For updated version, visit
        resume-helper.com!</button>

      <div>
        <small>Switch View: </small>
        <!-- View Switcher Buttons -->
        <div class="btn-group">
          <button class="btn"
            :class="{ 'btn-primary': currentView === 'preview', 'btn-secondary': currentView !== 'preview' }"
            @click="setView('preview')">
            Preview Only
          </button>
          <button class="btn"
            :class="{ 'btn-primary': currentView === 'both', 'btn-secondary': currentView !== 'both' }"
            @click="setView('both')">
            Both
          </button>
          <button class="btn"
            :class="{ 'btn-primary': currentView === 'editor', 'btn-secondary': currentView !== 'editor' }"
            @click="setView('editor')">
            Editor Only
          </button>
        </div>
      </div>
    </div>

    <!-- Dynamic Content Based on View -->
    <div class="row">
      <!-- Preview Section -->
      <div v-show="currentView === 'both' || currentView === 'preview'"
        :class="{ 'col-lg-6 ': currentView === 'both', 'col-lg-12': currentView === 'preview' }">
        <PreviewResumeComponent :resume-data="resumeData" />
      </div>

      <!-- Editor Section -->
      <div v-show="currentView === 'both' || currentView === 'editor'"
        :class="{ 'col-lg-6': currentView === 'both', 'col-lg-12': currentView === 'editor' }">
        <EditResumeContent :resume-data="resumeData" @update-resume="updateResumeData" />
      </div>
    </div>
  </div>
</template>

<script>
import PreviewResumeComponent from "@/components/ResumeHelper/PreviewResumeComponent.vue";
import EditResumeContent from "@/components/ResumeHelper/EditResumeComponent.vue";
import sampleCV from "@/assets/SampleCV.json";

export default {
  name: "ResumeHelperDemo",
  components: {
    PreviewResumeComponent,
    EditResumeContent,
  },
  data() {
    return {
      resumeData: sampleCV,
      currentView: "both", // Default view to show both components
    };
  },
  methods: {
    goBack() {
      this.$router.push('/');
    },
    redirectToHelper() {
      window.location.href = "https://resume-helper.com";
    },
    updateResumeData(updatedData) {
      this.resumeData = updatedData; // Sync changes from the editor
    },
    setView(view) {
      this.currentView = view; // Set the current view
    },
  },
};
</script>

<style scoped>
.pad20 {
  padding: 20px;
}



/* CTA Button */
.cta-link {
  display: inline-block;
  background: linear-gradient(69deg, #000069, #069069);
  color: white;
  padding: 0.75rem 1.5rem;
  border-radius: 5px;
  text-decoration: none;
  font-size: 1.2rem;
  font-weight: bold;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.cta-link:hover {
  background: linear-gradient(69deg, #000069, #069069);
  transform: translateY(-2px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.4);
}
</style>
