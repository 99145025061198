<template>
  <div class="mb-3">
    <!-- Display items as badges -->
    <div class="d-flex flex-wrap">
      <span
        v-for="(item, index) in localItems"
        :key="index"
        class="badge bg-primary m-1"
      >
        {{ item }}
        <button
          type="button"
          class="btn-close btn-sm ms-1"
          aria-label="Remove"
          @click="removeItem(index)"
        ></button>
      </span>
    </div>

    <!-- Input to add a new item -->
    <div class="input-group mt-2">
      <input
        type="text"
        class="form-control"
        v-model="newItem"
        @keyup.enter="addItem"
        :placeholder="placeholder"
      />
      <button
        class="btn btn-outline-primary"
        @click="addItem"
        :disabled="!newItem"
      >
        Add
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: "Skills",
    },
    placeholder: {
      type: String,
      default: "Add a new skill",
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      localItems: [...this.modelValue], // Local copy of the array
      newItem: "",
    };
  },
  watch: {
    modelValue: {
      immediate: true,
      handler(newValue) {
        this.localItems = [...newValue];
      },
    },
  },
  methods: {
    addItem() {
      if (this.newItem.trim()) {
        this.localItems.push(this.newItem.trim());
        this.newItem = "";
        this.emitUpdate();
      }
    },
    removeItem(index) {
      this.localItems.splice(index, 1);
      this.emitUpdate();
    },
    emitUpdate() {
      this.$emit("update:modelValue", [...this.localItems]);
    },
  },
};
</script>
