<template>
  <div class="user-metadata">
    <div class="col-12">
        <label for="fullName" class="form-label">Image Url</label>
        <input          type="text"          class="form-control"          id="userImage"
          v-model="userMetadataCopy.imageUrl"          @input="updateUserMetadata"
        />
      </div>
    <div class="row">
      <div class="col-6">
        <label for="fullName" class="form-label">Full Name</label>
        <input          type="text"          class="form-control"          id="fullName"
          v-model="userMetadataCopy.fullName"          @input="updateUserMetadata"
        />
      </div>
      <div class="col-6">
        <label for="jobTitle" class="form-label">Job Title</label>
        <input          type="text"          class="form-control"          id="jobTitle"
          v-model="userMetadataCopy.jobTitle"          @input="updateUserMetadata"
        />
      </div>
    </div>
    <div class="mb-3 row">
  <div class="col-md-6">
    <label for="email" class="form-label">Email</label>
    <input
      type="email"
      class="form-control"
      id="email"
      v-model="userMetadataCopy.email"
      @input="updateUserMetadata"
    />
  </div>

  <div class="col-md-6">
    <label for="phone" class="form-label">Phone</label>
    <input
      type="text"
      class="form-control"
      id="phone"
      v-model="userMetadataCopy.phone"
      @input="updateUserMetadata"
    />
  </div>
</div>

<div class="mb-3 row">
  <div class="col-md-6">
    <label for="location" class="form-label">Location</label>
    <input
      type="text"
      class="form-control"
      id="location"
      v-model="userMetadataCopy.location"
      @input="updateUserMetadata"
    />
  </div>

  <div class="col-md-6">
    <label for="linkedin" class="form-label">LinkedIn</label>
    <input
      type="text"
      class="form-control"
      id="linkedin"
      v-model="userMetadataCopy.linkedin"
      @input="updateUserMetadata"
    />
  </div>
</div>

<div class="mb-3 row">
  <div class="col-md-6">
    <label for="website" class="form-label">Website</label>
    <input
      type="text"
      class="form-control"
      id="website"
      v-model="userMetadataCopy.website"
      @input="updateUserMetadata"
    />
  </div>
</div>

  </div>
</template>

<script>
export default {
  name: "UserMetadataComponent",
  props: {
    userMetadata: Object,
  },
  data() {
    return {
      userMetadataCopy: { ...this.userMetadata },
    };
  },
  methods: {
    updateUserMetadata() {
      this.$emit("update-user-metadata", { ...this.userMetadataCopy });
    },
  },
};
</script>
